import React from "react";
import resumeFile from "../documents/Palak_Agarwal_-_DataDevOps_Engineer.pdf";
import { Tooltip } from "./Tooltip";
const AboutUs = ({ classicHeader, darkTheme }) => {
  return (
    <section id="about" className={"section " + (darkTheme ? "bg-dark-1" : "")}>
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            About Us
          </h2>
          {/* <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            Know Us More
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p> */}
        </div>
        {/* Heading end*/}
        <div className="row gy-5">
          {/* About me content start */}
          <div className="col-lg-7 col-xl-8 text-center text-lg-start">
            <h2
              className={
                "text-7 fw-600 mb-3 " + (darkTheme ? "text-white" : "")
              }
            >
              <center/>
              We are a <span className="text-primary">constantly evolving experimental space...</span>
            </h2>
            <p className={darkTheme ? "text-white-50" : ""}>
            We are comprised of talented and passionate young professionals. Geography is not a constraint for us as we work remotely, making the best use of technology and time. Reach out to us if you have an idea and need a help with it or if you are willing to build with us in the area of research & innovation.
            At Crafteak we are determined to provide a healthy learning environment to all our associates and a quality experience to our customers. 
            Join us to grow and learn...TOGETHER! 

            </p>
          </div>
          {/* About me content end
          {/* about me personal detials start */}
          {/* <div className="col-lg-5 col-xl-4">
            <div className="ps-lg-4">
              <ul
                className={
                  "list-style-2 " +
                  (darkTheme ? "list-style-light text-light" : "")
                }
              >
                <li>
                  <span className="fw-600 me-2">Name:</span>Palak Agarwal
                </li>
                <li>
                  <span className="fw-600 me-2">Email:</span>
                  <a href="mailto:chat@simone.com">palak.agarwal8987@gmail.com</a>
                </li>
                <li>
                <span className="fw-600 me-2">LinkedIn:</span>
                <span className="social-icons-linkedin">
                <Tooltip text="LinkedIn" placement="top">
                  <a href="https://www.linkedin.com/in/palak-agarwal-ba0789100/"
                     target="https://www.linkedin.com/in/palak-agarwal-ba0789100/"
                     rel="noopener noreferrer">
                    <i className="fab fa-linkedin" />
                  </a>
                </Tooltip>
              </span>
                </li>
                <li className="border-0">
                  <span className="fw-600 me-2">From:</span>Den Bosch, Netherlands
                </li>
                <li className="border-0">
                  <span className="fw-600 me-2">Work Experience:</span>10+ Years
                </li>
              </ul>
              <a href={resumeFile} download className="btn btn-primary rounded-pill">
              Download CV
              </a>
            </div>
          </div> */}
          {/* about me personal details end */}
        </div>
        {/* projects rewards counting start */}
        {/* <div
          className={
            "brands-grid separator-border mt-5 " +
            (darkTheme ? "separator-border-light" : "")
          }
        > */}
          {/* <div className="row">
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>10</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Years Experiance
                </p>
              </div>
            </div> */}
            {/* <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>250</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Happy Clients
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>650</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Projects Done
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>38</span>
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Get Awards
                </p>
              </div>
                </div> */}
          {/* </div>
        </div> */}
        {/* projects rewards counting end */}
      </div>
    </section>
  );
};

export default AboutUs;
